<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 个人页面 -->
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-position="right"
        :rules="rules"
        hide-required-asterisk
        label-width="125px"
        size="small"
        class="demo-ruleForm clearfix"
    >
        <el-form-item label="姓名" prop="cate">
            <el-input v-model.trim="ruleForm.cate" :disabled="ruleForm.disabled" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="ID">
            <el-input
                :disabled="ruleForm.disabled"
                v-model.trim="ruleForm.ID"
                placeholder="请输入身份证号码"
            ></el-input>
        </el-form-item>
        <el-form-item label="证件原件上传">
            <div class="imgtype">
                <el-upload
                    :disabled="ruleForm.disabled"
                    list-type="picture-card"
                    class="upload-demo"
                    :action="baseUrl + '/upload/pic/1'"
                    :on-success="handleAvatarSuccess1"
                    :before-upload="beforeAvatarUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove1"
                    :file-list="fileList1"
                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                >
                    <img v-if="!fileList1.length" class="on" src="@/assets/img/head.png" />
                    <p>头像面</p>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" />
                </el-dialog>
                <el-upload
                    :disabled="ruleForm.disabled"
                    list-type="picture-card"
                    class="upload-demo"
                    :action="baseUrl + '/upload/pic/1'"
                    :on-success="handleAvatarSuccess2"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="beforeAvatarUpload"
                    :on-remove="handleRemove2"
                    :file-list="fileList2"
                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                >
                    <img v-if="!fileList2.length" class="on" src="@/assets/img/emblem.png" />
                    <p>国徽面</p>
                </el-upload>
                <el-upload
                    class="upload-demo"
                    list-type="picture-card"
                    :action="baseUrl + '/upload/pic/1'"
                    :disabled="ruleForm.disabled"
                    :on-success="handleAvatarSuccess3"
                    :before-upload="beforeAvatarUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove3"
                    :file-list="fileList3"
                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                >
                    <img v-if="!fileList3.length" class="on" src="@/assets/img/hold.png" />
                    <p>手持证件</p>
                </el-upload>
            </div>
            <div class="upload_tip">
                <p class="p1">请上传身份证等有效证件的正反面照片或扫描件以及您的手持身份证照片，要求清晰无遮挡，</p>
                <p class="p2">支持.jpg.jpeg.png.bmp，每张图片大小不超过5M</p>
            </div>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
            <el-input v-model.trim="ruleForm.email" placeholder="请输入邮箱" :disabled="ruleForm.disabled"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="tel">
            <el-input
                v-model.trim="ruleForm.tel"
                placeholder="请填写联系人手机号"
                @change="changetel"
                style="width: 310px; height: 32px"
                :disabled="ruleForm.disabled"
            ></el-input>
            <el-button class="code_btn" :class="[ruleForm.tel ? '' : 'null']" :disabled="disabled" @click="getCodes">
                {{ catchCode }}
            </el-button>
        </el-form-item>
        <el-form-item v-show="ruleForm.yanoff" class="last" label="验证码" prop="password">
            <el-input
                :disabled="ruleForm.disabled"
                v-model.trim="ruleForm.password"
                @keyup.enter.native="submitPhone"
                placeholder="请填写验证码"
                style="width: 134px"
            ></el-input>
        </el-form-item>
        <el-form-item>
            <next-tick
                :btn1="1"
                :btn2="0"
                :btn3="1"
                :btn4="ruleForm.update ? 1 : 0"
                class="tick"
                @edit="edit"
                @nextTick="nextTick('ruleForm')"
            ></next-tick>
        </el-form-item>
    </el-form>
</template>

<script>
import { sendCode, verify, keyword, checkTwoFactor, info } from '@/api/getData.js';
import NextTick from '@/components/NextTick.vue';

export default {
    name: 'personal',
    components: {
        'next-tick': NextTick, // 下一步按钮
    },
    data() {
        return {
            cardIcon: require('@/assets/img/card.png'),
            value: '',
            off: true,
            baseUrl: process.env.NODE_ENV === 'production' ? location.origin : '/proxy',
            fileList1: [],
            fileList2: [],
            fileList3: [],
            imageUrl: '',
            imagesUrl: '',
            imagerUrl: '',
            disabled: false,
            setTimeNum: 60,
            catchCode: '获取验证码',
            dialogImageUrl: '',
            dialogVisible: false,
            timer: null, // 是否已存在计时器
            ruleForm: {
                cate: '',
                ID: '',
                email: '',
                yanoff: false,
                tel: '',
                password: '',
                disabled: false,
                update: false,
            },
            rules: {
                cate: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '您输入的姓名长度不正确，请重新输入',
                        trigger: 'blur',
                    },
                    {
                        message: '请填写您的有效姓名',
                        trigger: 'blur',
                    },
                ],
                ID: [
                    { required: true, message: '请输入身份证号码', trigger: 'blur' },
                    {
                        pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                        message: '您输入的身份证号码不正确,请重新输入',
                        trigger: 'blur',
                    },
                    {
                        min: 18,
                        max: 18,
                        message: '您输入的身份证号码长度不正确，请重新输入',
                        trigger: 'blur',
                    },
                ],
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    {
                        pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                        message: '请输入正确的邮箱地址',
                        trigger: 'blur',
                    },
                ],
                tel: [
                    { required: true, message: '请填写联系人手机号', trigger: 'blur' },
                    {
                        tmin: 11,
                        max: 11,
                        message: '您输入的手机号码长度不正确,请重新输入',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请填写验证码', trigger: 'blur' },
                    {
                        pattern: /^[0-9]{4}$/,
                        message: '请填写正确的验证码',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        edit() {
            // 编辑
            this.ruleForm.disabled = false;
            this.ruleForm.update = true;
            this.$emit('edit', 1);
        },
        changetel() {
            this.ruleForm.yanoff = true;
            this.ruleForm.password = '';
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (reg.test(this.ruleForm.tel)) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
        nextTick() {
            // 下一步
            const phonedata = {
                mobile_phone: this.ruleForm.tel,
                verify_code: this.ruleForm.password,
            };
            if (this.yan().off) {
                if (this.ruleForm.yanoff) {
                    const { cate, ID } = this.ruleForm;
                    checkTwoFactor({
                        id: ID,
                        name: cate,
                    }).then((res) => {
                        const { data } = res;
                        if (!data) {
                            this.$message.error('姓名与身份证号不匹配');
                        } else {
                            verify(phonedata).then((res) => {
                                const { code } = res;
                                if (code !== 200) {
                                    this.$message.error('请输入正确的验证码');
                                } else {
                                    sessionStorage.setItem('active', 1);
                                    if (!this.ruleForm.disabled) {
                                        sessionStorage.setItem('dis', 1);
                                        let type1 = sessionStorage.getItem('type1');
                                        sessionStorage.setItem('type', type1);
                                    }
                                    this.suces();
                                }
                            });
                        }
                    });
                } else {
                    sessionStorage.setItem('active', 1);
                    if (!this.ruleForm.disabled) {
                        let type1 = sessionStorage.getItem('type1');
                        sessionStorage.setItem('type', type1);
                        sessionStorage.setItem('dis', 1);
                    }
                    this.suces();
                }
            } else {
                this.$message.error(this.yan().msg);
            }
        },
        suces() {
            let imgList = [this.imageUrl, this.imagesUrl, this.imagerUrl];
            let subject = {
                ...this.ruleForm,
                imageUrl: imgList,
                type: sessionStorage.getItem('type1'),
            };
            // 将表单信息缓存起来
            keyword({
                key_word: '',
                owner_type: 0,
                lastPageNo: 0,
                page: 1,
                size: 10,
            }).then((res) => {
                sessionStorage.setItem('subject', JSON.stringify(subject));
                if (res?.data?.records?.length) {
                    this.$router.push({
                        path: '/hasownership',
                    });
                } else {
                    this.$router.push({
                        path: '/noownership',
                    });
                }
            });
        },
        yan() {
            let ruleForm = {
                cate: this.ruleForm.cate,
                ID: this.ruleForm.ID,
                email: this.ruleForm.email,
                tel: this.ruleForm.tel,
                password: this.ruleForm.password,
            };
            for (let key in ruleForm) {
                if (ruleForm[key].length === 0) {
                    this.off = false;
                }
            }
            let imgList = [this.imageUrl, this.imagesUrl, this.imagerUrl];
            this.off = imgList.every((item) => {
                return item;
            });
            if (!this.off) {
                return {
                    off: this.off,
                    msg: '请填写内容',
                };
            }
            let obj = {};
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    obj = {
                        off: this.off,
                        msg: '成功',
                    };
                } else {
                    obj = {
                        off: false,
                        msg: '请按照要求填写内容',
                    };
                }
            });
            return obj;
        },
        getCodes() {
            // 获取验证码
            if (this.ruleForm.disabled) {
                return;
            }
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!reg.test(this.ruleForm.tel)) {
                return;
            }
            this.changetel();
            this.setTime();
            sendCode({}, this.ruleForm.tel).then((res) => {});
        },
        handleAvatarSuccess1(res, file) {
            this.imageUrl = res.data[0];
            this.fileList1 = [
                {
                    url: res.data[0],
                },
            ];
        },
        handleAvatarSuccess2(res, file) {
            this.imagesUrl = res.data[0];
            this.fileList2 = [
                {
                    url: res.data[0],
                },
            ];
        },
        handleAvatarSuccess3(res, file) {
            this.imagerUrl = res.data[0];
            this.fileList3 = [
                {
                    url: res.data[0],
                },
            ];
        },
        beforeAvatarUpload(file) {
            const isJPEG = file.type === 'image/jpeg';
            const isJPG = file.type === 'image/jpg';
            const isPNG = file.type === 'image/png';
            const isBMP = file.type === 'image/bmp';
            const isLt5M = file.size / 1024 / 1024 < 5;

            if (!isJPG && !isJPEG && !isPNG && !isBMP) {
                this.$message.error('请上传格式为 JPG/JPEG/PNG/BMP 的文件');
            }
            if (!isLt5M) {
                this.$message.error('上传证件照片大小需小于5MB');
            }
            return isLt5M;
        },
        handleRemove1(file, fileList) {
            this.imageUrl = '';
            this.fileList1 = [];
        },
        handleRemove2(file, fileList) {
            this.imagesUrl = '';
            this.fileList2 = [];
        },
        handleRemove3(file, fileList) {
            this.imagerUrl = '';
            this.fileList3 = [];
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 验证是否注册
        async hasRegistFn() {
            let phone = this.ruleForm.username;
            if (phone !== '' && this.phoneTrue) {
                if (this.disabled) {
                    this.disabled = false;
                    // eslint-disable-next-line no-undef
                    let res = await hasRegistApi({ mobile: phone });
                    if (res.success) {
                        this.getCode();
                        this.registStatus = false;
                    } else {
                        this.registStatus = true;
                    }
                }
            } else {
                this.$refs.ruleForm.validateField('username');
            }
        },
        async getCode() {
            // eslint-disable-next-line no-undef
            let res = await getCodeRes({ mobile: this.ruleForm.username });
            if (!res.success) {
                if (res.message === 'isv.BUSINESS_LIMIT_CONTROL') {
                    this.$message({
                        type: 'error',
                        message: '当天发送短信已达到上限，请改天再试！',
                    });
                } else {
                    this.$message({ type: 'error', message: res.message });
                }
            } else {
                this.ruleForm.smsToken = res.data.smsToken;
                this.setTime();
            }
        },
        // 手机号提交
        submitPhone(formName) {
            this.$refs.ruleForm.validate((valid) => {
                this.loginIn(valid, this.ruleForm);
            });
        },
        beforeunload() {
            window.addEventListener('beforeunload', () => {
                sessionStorage.setItem(
                    'getTime',
                    JSON.stringify({
                        time: this.setTimeNum,
                        timer: this.timer,
                        verifyStatus: this.verifyStatus,
                    })
                );
            });
        },
        setTime() {
            this.disabled = true;
            if (!this.timer) {
                this.timer = setInterval(() => {
                    if (this.setTimeNum === 0) {
                        this.catchCode = '重新发送';
                        this.setTimeNum = 60;
                        clearInterval(this.timer);
                        this.timer = null;
                        this.disabled = false;
                        return false;
                    } else {
                        this.catchCode = this.setTimeNum + 's';
                        this.setTimeNum--;
                    }
                }, 1000);
            }
        },
    },
    created() {
        this.beforeunload();
    },
    mounted() {
        if (!this.yanoff) {
            this.ruleForm.password = '1111';
        } else {
            this.ruleForm.password = '';
        }
        let ruleForm = sessionStorage.getItem('subject');
        let offrus = {};
        if (ruleForm && ruleForm !== 'undefined') {
            offrus = JSON.parse(ruleForm);
        }
        if (!offrus.update) {
            info().then((res) => {
                if (res.code === 200 && res.data.user_type === 1) {
                    if (res.data.user_status === 2) {
                        this.ruleForm.disabled = false;
                        this.ruleForm.update = true;
                    } else {
                        this.ruleForm.disabled = true;
                        this.ruleForm.update = false;
                        this.ruleForm.cate = res.data.user_name;
                        [this.imageUrl, this.imagesUrl, this.imagerUrl] = res.data.pics_url;
                        this.fileList1 = [{ url: this.imageUrl }];
                        this.fileList2 = [{ url: this.imagesUrl }];
                        this.fileList3 = [{ url: this.imagerUrl }];
                        this.ruleForm.ID = res.data.id_card;
                        this.ruleForm.email = res.data.email;
                        this.ruleForm.tel = res.data.mobile_phone;
                    }
                } else {
                    this.ruleForm.update = true;
                }
            });
        } else {
            let ruleForm = sessionStorage.getItem('subject');
            if (ruleForm && ruleForm !== 'undefined') {
                let offru = JSON.parse(ruleForm);
                if (offru.type === 'false') {
                    this.ruleForm = offru;
                    [this.imageUrl, this.imagesUrl, this.imagerUrl] = JSON.parse(ruleForm).imageUrl;
                    this.fileList1 = [{ url: this.imageUrl }];
                    this.fileList2 = [{ url: this.imagesUrl }];
                    this.fileList3 = [{ url: this.imagerUrl }];
                    this.fileList = this.ruleForm.fileList;
                    this.verifyStatus = this.ruleForm.verifyStatus;
                } else {
                    this.ruleForm.update = true;
                }
            }
        }
        // if (location.hostname === 'localhost') {
        //     this.baseUrl = '/proxy';
        // } else if (location.hostname === '127.0.0.1') {
        //     this.baseUrl = '/proxy';
        // } else {
        //     this.baseUrl = location.origin;
        // }
        const setTime = JSON.parse(sessionStorage.getItem('getTime'));
        const { time, timer, verifyStatus } = setTime || {};
        if (timer && !verifyStatus) {
            this.setTimeNum = time;
            this.verifyStatus = verifyStatus;
            this.setTime();
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', () => {
            sessionStorage.removeItem('getTime');
        });
    },
    watch: {
        'ruleForm.tel': {
            handler(val) {
                const pattern = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (val && pattern.test(val)) {
                    if (this.setTimeNum === 60) {
                        this.disabled = false;
                    }
                }
            },
        },
    },
};
</script>
<style lang="less" scoped>
.el-form-item--mini.el-form-item {
    margin-bottom: 28px;
}
.el-form-item--small.el-form-item {
    margin-bottom: 28px;
}
.el-form-item__label {
    color: #383d47;
}
.el-form-item {
    text-align: left;
}
.el-input {
    width: 420px;
    height: 32px;
}
::v-deep .upload-demo .el-upload--picture-card {
    background: transparent;
    border: none;
    .on {
        position: absolute;
        top: 0;
        left: 0;
    }
}
::v-deep .el-upload-list--picture-card {
    display: block;
    position: absolute;
    z-index: 999;
}
::v-deep .el-upload--picture-card:hover {
    border-color: transparent;
    color: #2d63e0;
}
.imgtype {
    width: 380px;
    height: 109px;
    display: flex;
    justify-content: space-between;
    .el-upload--picture-card {
        img {
            width: 118px;
            height: 73px;
            border: 1px dashed #cfd4df;
            border-radius: 6px;
            &:hover {
                border: 1px solid #2d63e0;
            }
        }
    }
    p {
        position: absolute;
        bottom: 8px;
        left: 37px;
        font-size: 12px;
        color: #b0b2c4;
        letter-spacing: 0;
        line-height: 14px;
        margin-top: -10px;
    }
}

.upload-demo {
    width: 120px;
    text-align: left;
    position: relative;
    img {
        margin-bottom: 8px;
    }
    // .el-upload {
    //   border: 1px dashed #d9d9d9;
    //   border-radius: 6px;
    //   cursor: pointer;
    //   overflow: hidden;
    // }
    // .el-upload:hover {
    //   border-color: #409EFF;
    //   z-index: 999;
    // }
}
.upload_tip {
    font-size: 12px;
    color: #b0b2c4;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 2px;
    text-align: left;
    font-weight: lighter;
}

/deep/ .el-upload {
    width: 120px;
    height: 75px;
}

/deep/ .el-upload-list__item {
    width: 120px;
    height: 75px;
}

::v-deep .code_btn {
    background: rgba(45, 99, 224, 0.14);
    border-radius: 4px;
    width: 94px;
    height: 32px;
    line-height: 15px;
    cursor: pointer;
    font-size: 14px;
    border: none;
    letter-spacing: 0;
    margin-left: 16px;
    color: #2858c7;
    opacity: 0.7;
    span {
        position: relative;
        top: -1px;
        font-weight: normal;
    }
}
.code_btn.null {
    background: #f5f7fc;
    opacity: 0.3;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #2d63e0;
    cursor: not-allowed;
    background-image: none;
    background-color: #f5f7fc;
    border-color: transparent;
}
.time_btn {
    color: #000;
    cursor: default;
}

.tick {
    margin-left: -10px;
}
</style>