<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 组织页面 -->
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-position="right"
        :rules="rules"
        hide-required-asterisk
        label-width="125px"
        size="small"
        class="demo-ruleForm clearfix"
    >
        <el-form-item label="名称" prop="cate">
            <el-input v-model.trim="ruleForm.cate" placeholder="请输入名称" :disabled="ruleForm.disabled"></el-input>
        </el-form-item>
        <el-form-item label="证件编号" prop="number">
            <el-input
                v-model.trim="ruleForm.number"
                placeholder="请填写执照的社会统一信用代码"
                :disabled="ruleForm.disabled"
            ></el-input>
        </el-form-item>
        <el-form-item label="证件原件上传">
            <el-upload
                ref="uploads"
                class="upload-demo"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                :on-exceed="handleExceed"
                :disabled="ruleForm.disabled"
                :file-list="fileList"
                :action="baseUrl + '/upload/pic/2'"
                accept="image/png, image/jpeg, image/jpg, image/bmp"
            >
                <img :src="cardIcon" alt="" width="100%" height="100%" />
                <div slot="tip" class="upload_tip">
                    <p class="p1">请上传企业营业执照或其他组织机构的资质证明文件扫描件或数码照片，要求清晰可见，</p>
                    <p class="p2">支持.jpg.jpeg.png.bmp，图片大小不超过20M</p>
                </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
        </el-form-item>
        <el-form-item label="证件有效期" prop="time">
            <el-date-picker
                v-model="ruleForm.time"
                class="picker"
                :disabled="ruleForm.disabled"
                type="daterange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="['00:00:00', '23:59:59']"
                @change="onPick"
            >
            </el-date-picker>
            <el-checkbox v-model="ruleForm.longTime" :disabled="ruleForm.disabled" @change="longChange">
                长期有效
            </el-checkbox>
        </el-form-item>
        <el-form-item label="法人代表姓名" prop="name">
            <el-input
                v-model.trim="ruleForm.name"
                :disabled="ruleForm.disabled"
                placeholder="请输入法人代表姓名"
            ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
            <el-input
                v-model.trim="ruleForm.contacts"
                :disabled="ruleForm.disabled"
                placeholder="请输入联系人"
            ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
            <el-input v-model.trim="ruleForm.email" :disabled="ruleForm.disabled" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="tel">
            <el-input
                v-model.trim="ruleForm.tel"
                placeholder="请填写联系人手机号"
                :disabled="ruleForm.disabled"
                @change="changetel"
                style="width: 310px; height: 32px"
            ></el-input>
            <el-button class="code_btn" :class="[ruleForm.tel ? '' : 'null']" :disabled="disabled" @click="getCodes">
                {{ catchCode }}
            </el-button>
        </el-form-item>
        <el-form-item v-show="ruleForm.yanoff" class="last" label="验证码" prop="password">
            <el-input
                :disabled="ruleForm.disabled"
                v-model.trim="ruleForm.password"
                @keyup.enter.native="submitPhone"
                placeholder="请填写验证码"
                style="width: 134px"
            ></el-input>
        </el-form-item>
        <el-form-item>
            <next-tick
                :btn1="1"
                :btn2="0"
                :btn3="1"
                :btn4="ruleForm.update ? 1 : 0"
                class="tick"
                @nextTick="nextTick('ruleForm')"
                @edit="edit"
            ></next-tick>
        </el-form-item>
    </el-form>
</template>

<script>
import { sendCode, verify, keyword, info } from '@/api/getData.js';
import { rTime } from '@/utils/public.js';
import NextTick from '@/components/NextTick.vue';

export default {
    name: 'organization',
    components: {
        'next-tick': NextTick, // 下一步按钮
    },
    data() {
        return {
            cardIcon: require('@/assets/img/card.png'),
            catchCode: '获取验证码',
            disabled: true,
            setTimeNum: 60,
            baseUrl: process.env.NODE_ENV === 'production' ? location.origin : '/proxy',
            timer: null, // 是否已存在计时器
            dialogImageUrl: '',
            imageUrl: [],
            timeout: false,
            fileList: [],
            dialogVisible: false,
            ruleForm: {
                edited: false,
                yanoff: false,
                longTime: false,
                cate: '',
                disabled: false,
                update: false,
                name: '',
                off: false,
                number: '',
                email: '',
                tel: '',
                password: '',
                time: '',
                contacts: '',
            },
            rules: {
                cate: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    {
                        min: 1,
                        max: 40,
                        message: '长度在 40 个字符以内',
                        trigger: 'blur',
                    },
                ],
                time: [
                    { required: true, message: '请选择证件有效期', trigger: 'change' },
                    { required: true, message: '请选择证件有效期', trigger: 'blur' },
                ],
                number: [
                    { required: true, message: '请输入证件编号', trigger: 'blur' },
                    {
                        min: 3,
                        max: 30,
                        message: '长度在 3 - 30 个字符以内',
                        trigger: 'blur',
                    },
                    { validator: this.validateNumber, trigger: ['blur', 'change'] }
                ],
                contacts: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '您输入的联系人长度不正确，请重新输入',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^[\u4e00-\u9fa5]*$/,
                        message: '请填写的有效联系人',
                        trigger: 'blur',
                    },
                ],
                name: [
                    { required: true, message: '请输入法人代表姓名', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '法人代表姓名长度不正确，请重新输入',
                        trigger: 'blur',
                    },
                ],
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    {
                        pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                        message: '请输入正确的邮箱地址',
                        trigger: 'blur',
                    },
                ],
                tel: [
                    { required: true, message: '请填写联系人手机号', trigger: 'blur' },
                    {
                        min: 11,
                        max: 11,
                        message: '您输入的手机号码长度不正确,请重新输入',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                        message: '您输入的手机号码不正确,请重新输入',
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请填写验证码', trigger: 'blur' },
                    {
                        pattern: /^[0-9]{4}$/,
                        message: '请填写正确的验证码',
                        trigger: 'blur',
                    },
                ],
            },
            verifyStatus: false,
        };
    },
    methods: {
        /**
         * @description: 证件编号验证
         * @param {*} rule 验证规则
         * @param {*} value 输入的值
         * @param {*} callback 回调函数
         * @return {*}
         */
        validateNumber(rule, value, callback) {
            const regex = /^[A-Za-z0-9]+$/;
            if (!value) {
                callback(new Error('请输入证件编号'));
            } else if (!regex.test(value)) {
                callback(new Error('证件编号只能包含字母和数字'));
            } else {
                callback();
            }
        },
        longChange() {
            // 长期有效复选框
            if (this.ruleForm.longTime) {
                this.ruleForm.time = [rTime('2000-01-01'), rTime('2099-12-31')];
            } else {
                this.ruleForm.time = [];
            }
        },
        edit() {
            // 编辑
            this.ruleForm.edited = true;
            this.ruleForm.disabled = false;
            this.ruleForm.update = true;
            this.$emit('edit', 1);
        },
        nextTick() {
            // 下一步
            if (!this.ruleForm.update && this.timeout) {
                this.$notify.error({
                    title: '证件过期',
                    message: '当前证件已过期',
                });
                return;
            }
            const data = {
                mobile_phone: this.ruleForm.tel,
                verify_code: this.ruleForm.password,
                edited: this.ruleForm.edited,
            };
            if (this.yan().off) {
                if (!this.onPick()) {
                    return;
                } else {
                    if (this.ruleForm.yanoff) {
                        verify(data).then((res) => {
                            const { code } = res;
                            if (code !== 200) {
                                this.$message.error('请输入正确的验证码');
                            } else {
                                sessionStorage.setItem('active', 1);
                                if (!this.ruleForm.disabled) {
                                    sessionStorage.setItem('dis', 1);
                                    let type1 = sessionStorage.getItem('type1');
                                    sessionStorage.setItem('type', type1);
                                }
                                this.suces();
                            }
                        });
                    } else {
                        sessionStorage.setItem('active', 1);
                        if (!this.ruleForm.disabled) {
                            sessionStorage.setItem('dis', 1);
                            let type1 = sessionStorage.getItem('type1');
                            sessionStorage.setItem('type', type1);
                        }
                        this.suces();
                    }
                }
            } else {
                this.$message.error(this.yan().msg);
            }
        },
        suces() {
            let imgarr = [];
            let arr = [];
            if (this.ruleForm.update) {
                this.imageUrl.forEach((item) => {
                    arr.push(item);
                });
            } else {
                this.fileList.forEach((item) => {
                    imgarr.push(item.url);
                });
            }
            let subject = {
                ...this.ruleForm,
                type: sessionStorage.getItem('type1'),
                imageUrl: this.ruleForm.update ? arr : imgarr,
            };
            // 将表单信息缓存起来
            keyword({
                key_word: '',
                owner_type: 0,
                lastPageNo: 0,
                page: 1,
                size: 10,
            }).then((res) => {
                sessionStorage.setItem('subject', JSON.stringify(subject));
                if (res?.data?.records?.length) {
                    this.$router.push({
                        path: '/hasownership',
                    });
                } else {
                    this.$router.push({
                        path: '/noownership',
                    });
                }
            });
        },
        changetel() {
            this.ruleForm.yanoff = true;
            this.ruleForm.password = '';
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (reg.test(this.ruleForm.tel)) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
        yan() {
            let off = true;
            let ruleForm = {
                cate: this.ruleForm.cate,
                name: this.ruleForm.name,
                number: this.ruleForm.number,
                email: this.ruleForm.email,
                tel: this.ruleForm.tel,
                password: this.ruleForm.password,
                time: this.ruleForm.time,
                contacts: this.ruleForm.contacts,
            };
            for (let key in ruleForm) {
                if (this.ruleForm[key] === null || ruleForm[key].length === 0) {
                    off = false;
                }
            }
            if (!this.fileList.length) {
                off = false;
                return {
                    off,
                    msg: '请上传图片材料',
                };
            }
            if (!off) {
                return {
                    off,
                    msg: '请填写内容',
                };
            }
            let obj = {};
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    obj = {
                        off,
                        msg: '成功',
                    };
                } else {
                    obj = {
                        off: false,
                        msg: '请按照要求填写内容',
                    };
                }
            });
            return obj;
        },
        getCodes() {
            // 获取验证码
            if (this.ruleForm.disabled) {
                return;
            }
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!reg.test(this.ruleForm.tel)) {
                return;
            }
            this.changetel();
            this.setTime();
            sendCode({}, this.ruleForm.tel).then((res) => {});
        },
        onPick(maxDate) {
            if (maxDate === null) {
                this.ruleForm.longTime = false;
                return;
            }
            let startTime = rTime(this.ruleForm.time[0]);
            let endTime = rTime(this.ruleForm.time[1]);
            if (startTime === '2000-01-01' && endTime === '2099-12-31') {
                this.ruleForm.longTime = true;
            } else {
                this.ruleForm.longTime = false;
            }
            if (this.ruleForm.time === null) {
                return;
            }
            if (Date.parse(this.ruleForm.time[1]) - Date.parse(new Date()) > 0) {
                return true;
            } else {
                this.$message.error('证件结束时间应当大于当前时间');
                return false;
            }
        },
        handleAvatarSuccess(res, file) {
            this.fileList = this.$refs.uploads.uploadFiles;
            let arr = [];
            this.fileList.forEach((item) => {
                if (item.response) {
                    item.url = item.response.data[0];
                }
                arr.push(item.url);
            });
            this.imageUrl = arr;
        },
        beforeAvatarUpload(file) {
            const isJPEG = file.type === 'image/jpeg';
            const isJPG = file.type === 'image/jpg';
            const isPNG = file.type === 'image/png';
            const isBMP = file.type === 'image/bmp';
            const isLt5M = file.size / 1024 / 1024 < 20;
            if (!isJPG && !isJPEG && !isPNG && !isBMP) {
                this.$message.error('请上传格式为 JPG/JPEG/PNG/BMP 的文件');
            }
            if (!isLt5M) {
                this.$message.error('上传证件照片大小需小于20MB');
            }
            return isLt5M;
        },
        handleRemove(file, fileList) {
            this.fileList = this.$refs.uploads.uploadFiles;
            let arr = [];
            this.fileList.forEach((item) => {
                arr.push(item.url);
            });
            this.imageUrl = arr;
            this.flag = false;
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleExceed(files, fileList) {
            this.$message.warning('只能上传1张图片');
        },
        // 验证是否注册
        async hasRegistFn() {
            let phone = this.ruleForm.username;
            if (phone !== '' && this.phoneTrue) {
                if (this.disabled) {
                    this.disabled = false;
                    // eslint-disable-next-line no-undef
                    let res = await hasRegistApi({ mobile: phone });
                    if (res.success) {
                        this.getCode();
                        this.registStatus = false;
                    } else {
                        this.registStatus = true;
                    }
                }
            } else {
                this.$refs.ruleForm.validateField('username');
            }
        },
        async getCode() {
            // eslint-disable-next-line no-undef
            let res = await getCodeRes({ mobile: this.ruleForm.username });
            if (!res.success) {
                if (res.message === 'isv.BUSINESS_LIMIT_CONTROL') {
                    this.$message({
                        type: 'error',
                        message: '当天发送短信已达到上限，请改天再试！',
                    });
                } else {
                    this.$message({ type: 'error', message: res.message });
                }
            } else {
                this.ruleForm.smsToken = res.data.smsToken;
                this.setTime();
            }
        },
        // 手机号提交
        submitPhone(formName) {
            this.$refs.ruleForm.validate((valid) => {
                this.loginIn(valid, this.ruleForm);
            });
        },
        beforeunload() {
            window.addEventListener('beforeunload', () => {
                sessionStorage.setItem(
                    'setTime',
                    JSON.stringify({
                        time: this.setTimeNum,
                        timer: this.timer,
                        verifyStatus: this.verifyStatus,
                        disabled: this.disabled,
                    })
                );
            });
        },
        setTime() {
            this.disabled = true;
            if (!this.timer) {
                this.timer = setInterval(() => {
                    if (this.setTimeNum === 0) {
                        this.catchCode = '重新发送';
                        this.setTimeNum = 60;
                        clearInterval(this.timer);
                        this.timer = null;
                        this.disabled = false;
                        return false;
                    } else {
                        this.catchCode = this.setTimeNum + 's';
                        this.setTimeNum--;
                    }
                }, 1000);
            }
        },
    },
    created() {
        this.beforeunload();
    },
    mounted() {
        if (!this.yanoff) {
            this.ruleForm.password = '1111';
        } else {
            this.ruleForm.password = '';
        }
        let offrus = {};
        let ruleForm = sessionStorage.getItem('subject');
        if (ruleForm) {
            offrus = JSON.parse(ruleForm);
        }
        if (!offrus.update && ruleForm !== 'undefined') {
            info().then((res) => {
                if (res.code === 200 && res.data.user_type === 2) {
                    if (res.data.user_status === 2) {
                        this.ruleForm.disabled = false;
                        this.ruleForm.off = false;
                        this.ruleForm.update = true;
                    } else {
                        this.ruleForm.disabled = true;
                        this.ruleForm.off = true;
                        this.ruleForm.update = false;
                        this.ruleForm.cate = res.data.user_name;
                        this.ruleForm.number = res.data.company_code;
                        this.fileList = [{ url: res.data.license_url }];
                        let arr = [];
                        this.fileList.forEach((item) => {
                            arr.push(item.url);
                        });
                        this.imageUrl = arr;
                        this.ruleForm.time = [rTime(res.data.card_start_date), rTime(res.data.card_end_date)];
                        this.ruleForm.contacts = res.data.contact_name;
                        this.ruleForm.name = res.data.legal_person;
                        this.ruleForm.email = res.data.email;
                        this.ruleForm.tel = res.data.mobile_phone;
                        let startTime = rTime(this.ruleForm.time[0]);
                        let endTime = rTime(this.ruleForm.time[1]);
                        if (startTime === '2000-01-01' && endTime === '2099-12-31') {
                            this.ruleForm.longTime = true;
                        } else {
                            this.ruleForm.longTime = false;
                        }
                    }
                } else {
                    this.ruleForm.update = true;
                }
            });
        } else {
            let ruleForm = sessionStorage.getItem('subject');
            if (ruleForm && ruleForm !== 'undefined') {
                let offru = JSON.parse(ruleForm);
                if (offru.type === 'true') {
                    this.ruleForm = offru;
                    this.imageUrl = this.ruleForm.imageUrl;
                    this.ruleForm.imageUrl.forEach((item) => {
                        this.fileList.push({ url: item });
                    });
                    this.verifyStatus = this.ruleForm.verifyStatus;
                } else {
                    this.ruleForm.update = true;
                }
            }
        }
        // if (location.hostname === 'localhost') {
        //     this.baseUrl = '/proxy';
        // } else if (location.hostname === '127.0.0.1') {
        //     this.baseUrl = '/proxy';
        // } else {
        //     this.baseUrl = location.origin;
        // }
        const setTime = JSON.parse(sessionStorage.getItem('setTime'));
        const { time, timer, verifyStatus, disabled } = setTime || {};
        if (timer && !verifyStatus) {
            this.setTimeNum = time;
            this.disabled = disabled;
            this.setTime();
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', () => {
            sessionStorage.removeItem('setTime');
        });
    },
    watch: {
        'ruleForm.tel': {
            handler(val) {
                const pattern = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (val && pattern.test(val)) {
                    if (this.setTimeNum === 60) {
                        this.disabled = false;
                    }
                } else {
                    this.disabled = true;
                }
            },
        },
    },
};
</script>
<style>
.el-date-table td.available:hover {
    color: #2d63e0 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2d63e0;
    border-color: #2d63e0;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
    color: #2d63e0;
}
.el-date-table td.end-date span {
    background-color: #2d63e0;
}
.el-date-table td.start-date span {
    background-color: #2d63e0;
}
.el-date-table td span {
    border-radius: 2px;
}
.el-date-table td.in-range div {
    background-color: rgba(45, 99, 224, 0.1);
}
.el-date-table td.in-range div:hover {
    background-color: rgba(45, 99, 224, 0.1);
}
.el-date-table.is-week-mode .el-date-table__row.current div {
    background-color: rgba(45, 99, 224, 0.1);
}
.el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: rgba(45, 99, 224, 0.1);
}
.el-date-table td.end-date div {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.el-date-table td.start-date div {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.el-date-table td.today span {
    color: #2d63e0;
}
[class*=' el-icon-'],
[class^='el-icon-'] {
    color: #d3d1e0;
}
[class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #fff;
}
.el-form-item__label {
    color: #383d47;
}
</style>
<style lang="less" scoped>
::v-deep .el-checkbox {
    margin-left: 50px;
}
::v-deep .el-checkbox__label {
    padding-left: 5px;
}
::v-deep .el-upload-list--picture-card {
    display: inline-block;
    height: 85px;
}
::v-deep .el-upload-list.is-disabled + .el-upload img:hover {
    cursor: no-drop;
}
.el-form-item--mini.el-form-item {
    margin-bottom: 28px;
}
.el-form-item--small.el-form-item {
    margin-bottom: 28px;
}
.el-form-item {
    text-align: left;
}
.el-input {
    width: 420px;
    height: 32px;
}

.picker {
    width: 420px;
    height: 32px;
}
.upload-demo {
    text-align: left;
    img {
        margin-bottom: 10px;
    }
}
.upload_tip {
    font-size: 12px;
    color: #b0b2c4;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: lighter;
}

/deep/ .el-upload {
    width: 120px;
    height: 75px;
}

/deep/ .el-upload-list__item {
    width: 120px;
    height: 75px;
}

::v-deep .code_btn {
    background: #f5f7fc;
    border-radius: 4px;
    width: 94px;
    height: 32px;
    line-height: 15px;
    cursor: pointer;
    font-size: 14px;
    border: none;
    letter-spacing: 0;
    margin-left: 16px;
    color: #2858c7;
    opacity: 0.7;
    span {
        position: relative;
        top: -1px;
        font-weight: normal;
    }
}
.code_btn.null {
    background: #f5f7fc;
    opacity: 0.3;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #2d63e0;
    cursor: not-allowed;
    background-image: none;
    background-color: #f5f7fc;
    border-color: transparent;
}
.time_btn {
    color: #000;
    cursor: default;
}

.tick {
    margin-left: -10px;
}
</style>